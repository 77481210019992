import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/dist/sync-engine-client/lib/models/storedData';
import { SyncInformation } from '@vending/sync-engine-client/dist/sync-engine-client/lib/models/syncInformation';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { MachineModel } from 'src/app/models/machine';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class MachineService extends OfflineDataService<MachineModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Machine',
      http,
      errorService,
      events,
      'machines/',
      'machine',
      [
        'created_at',
        'updated_at',
        'created_by_id',
        'customer',
        'open_damages',
        'category',
      ],
      []
    );
  }

  /**
   * Sync überschrieben, so dass die Bilder offline gecached werden
   * @param {SyncInformation} info
   * @return {Promise<booelan>}
   */
  override async sync(info: SyncInformation): Promise<boolean> {
    const result = await super.sync(info);

    if (!info.deleted_at) {
      const machineStoredData: StoredData<MachineModel> = await this.localFind(
        info.entity_id
      );
      const machine = machineStoredData.content;
      this.preloadImage(machine.thumbnail);
      if (machine.images) {
        machine.images.forEach((image: any) => {
          this.preloadImage(image as string);
        });
      }
    }

    return Promise.resolve(result);
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'cafe-outline';
  }

  saveRemote(machine: MachineModel): Observable<MachineModel> {
    if (machine.id >= 0) {
      return this.http
        .put<MachineModel>(
          this.endpointWithUrl + machine.id,
          toFormData(machine, this, ['images'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<MachineModel>(
          this.endpointWithUrl,
          toFormData(machine, this, ['images'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }
}
