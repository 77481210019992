import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  /**
   * Rollen laden für eine relative url
   * @param {string} url
   * @return {string[]}
   */
  public getRoles(url: string): string[] {
    if (url && url.substring(0, 1) === '/') {
      url = url.substring(1);
    }
    return ROUTE_AND_ROLE[url] || [];
  }
}

const ROUTE_AND_ROLE = {
  statuses: ['Status auflisten'],
  'statuses/create': ['Status erstellen'],
  'statuses/delete': ['Status löschen'],
  boilerplates: ['Textbausteine auflisten'],
  priorities: ['Prioritäten auflisten'],
  function_switches: ['FunktionSwitch auflisten'],
  client_devices: ['Mobilgeräte auflisten'],
  damage_categories: ['Schadenskategorie auflisten'],
  users: ['User auflisten'],
  mandators: ['Mandator auflisten'],
  'settings/cars': ['Fahrzeuge ansehen'],
  customers: ['Kunden auflisten'],
  distributors: ['Lieferanten auflisten'],
  articles: ['Artikel auflisten'],
  article_units: ['Einheiten auflisten'],
  article_groups: ['Artikelgruppen auflisten'],
  machines: ['Maschinen auflisten'],
  machine_types: ['Maschinentyp auflisten'],
  machine_groups: ['Maschinengruppen auflisten'],
  machine_states: ['Maschinenstatus auflisten'],
  settings: ['Einstellungen ansehen'],
  dashboard: ['Dashboard ansehen'],
  service_contracts: ['Backend Servicevaufträge auflisten'],
  checklists: ['Checklisten auflisten'],
  templates: ['Templates auflisten'],
  damages_reports: ['Schadensberichte auflisten'],
  'damageManagement/damage_reports': ['Backend Schadensberichte auflisten'],
  damage_reports: ['Backend Schadensberichte auflisten'],
  uploadqueue: ['Uploadqueue auflisten'], // Dashboard ALT
};
