import { Component, Input } from '@angular/core';
import { EventsService } from 'src/app/providers/events.service';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';

@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss'],
})
export class AppLogoComponent {
  @Input() height = '75px';

  /** The image to display as logo */
  imgSource = 'assets/logo_small.png';

  constructor(
    private readonly eventsService: EventsService,
    private readonly userHelper: UserHelper
  ) {
    this.eventsService.subscribe('userHelper:loadedUser').subscribe(() => {
      const logo = this.userHelper.getManBus()?.mandator?.logo64;
      this.imgSource = logo ? logo : this.imgSource;
    });
  }
}
