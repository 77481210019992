
export class GlobalHelper {
    /**
     * Erstellt eine tiefe Kopie eines Objektes
     * @param {T} obj 
     * @return {T}
     */
    public static deepClone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    /**
     * Gibt immer einen String zurück. Leerer String wenn Null
     * @param value 
     * @returns 
     */
    public static nullToEmptyString(value: string | null): string {
        return value || '';
    }


    /**
     * Vergleihc von zwei Iso Dates.
     * aDateIso >= bDateIso
     * @param {string} aDateIso 
     * @param {string} bDateIso 
     * @return {boolean}
     */
    public static dateGreaterEquals(aDateIso: string, bDateIso: string): boolean {
        const d1 = new Date(aDateIso);
        const d2 = new Date(bDateIso);

        return d1.getTime() >= d2.getTime()
    }

    /**
     * Einen moment Warten und ein Schläfchen machen zZzzZZz
     * @param {number} time 
     */
    public static async sleep(time: number){
        await new Promise(r => setTimeout(r, time));
    }
}