import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { IonMenu } from '@ionic/angular';
import { KeycloakService } from 'keycloak-angular';
import { filter, map } from 'rxjs';
import { PageService } from 'src/app/providers/page-services/page.service';
import { VERSION } from '../assets/environments/version';
import {
  DAMAGE_REPORTS_MACHINE,
  DAMAGE_REPORTS_ORDER,
} from './functionSwitch.constants';
import { BusinessModel } from './models/settings/business';
import { MandatorModel } from './models/settings/mandator';
import { ISideMenu } from './models/sidemenu/sidemenu';
import {
  IMenuBasicElement,
  ISideMenuElement,
} from './models/sidemenu/sidemenu-element';
import { FunctionSwitchHelperService } from './providers/component-helpers/function-switch.service';
import { OrdersHelper } from './providers/component-helpers/orders-helper.service';
import { ToastService } from './providers/component-helpers/toast.service';
import { EventsService } from './providers/events.service';
import { ErrorLogHelper } from './providers/helpers/errorlogs-helper.service';
import { UserHelper } from './providers/helpers/user-helper.service';
import { SyncWrapperService } from './providers/syncWrapper.service';
import { AccessGuard } from './services/access.guard';

// How often should the badges be reloaded (orders)
const RELOAD_ORDERS_TIMER = 120 * 1000;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /** Version der App (Jahr*.Kalenderwoche*.Minor) *Zeitpunkt des Sprintstarts */
  readonly version: string = VERSION;
  /** Hier wird das komplette Side-Menu definiert */
  sideMenu: ISideMenu;
  /** Momentan ausgewählte/geöffnete Seite/Page */
  selectedPage: string;
  /** Ausgewählter Mandant */
  selectedMandator: MandatorModel;
  /** Ausgewähltes Business */
  selectedBusiness: BusinessModel;
  /** Is the device currently online? */
  isOnline = false;
  /** interval for loading all roles */
  intervalLoadingRoles: any;
  /** INterval for loading errorlogs (interval depends on user-group) */
  intervalErrorlog: any;
  /** Interval for loading orders count */
  intervalReloadOrders: any;
  /** Subscription of the event when the user gets loaded */
  subscriptionUserLoaded: any;

  @ViewChild('ionicMenu') ionicMenuComponent: IonMenu;

  private appConfigured = false;
  private networkListener: PluginListenerHandle;
  private _usesDamagereportsOnOrders: boolean;
  private _usesDamagereportsOnMachines: boolean;

  constructor(
    public readonly errorLogHelper: ErrorLogHelper,
    public readonly toastService: ToastService,
    public readonly userHelper: UserHelper,
    private readonly accessGuard: AccessGuard,
    private readonly events: EventsService,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly keycloakService: KeycloakService,
    private readonly ordersHelper: OrdersHelper,
    private readonly pageService: PageService,
    private readonly router: Router,
    private readonly swUpdate: SwUpdate,
    private readonly syncWrapperService: SyncWrapperService
  ) {
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.debug(
            `[AppComponent] Downloading new app version: ${evt.version.hash}`
          );
          break;
        case 'VERSION_READY':
          console.debug(
            '[AppComponent] Update ready from "',
            evt.currentVersion.hash,
            '" to "',
            evt.latestVersion.hash,
            '"'
          );
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.debug(
            `[AppComponent] Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );

    updatesAvailable.subscribe((evt) => {
      console.debug(
        '[AppComponent] Update available from "',
        evt.current,
        '" to "',
        evt.available,
        '"'
      );

      if (evt.available !== evt.current)
        this.syncWrapperService.newVersion = true;
    });
  }

  ngOnInit() {
    this.buildAndSetAccess();
    this.initializeCurrentPageListener();
    this.initializeNetworkCheck();

    this.subscriptionUserLoaded = this.events
      .subscribe('userHelper:loadedUser')
      .subscribe(() => {
        this.syncWrapperService.startSync();
        this.subscriptionUserLoaded.unsubscribe();
      });

    this.events.subscribe('logout').subscribe(() => {
      this.logout();
    });

    this.reloadCounterData();
    this.intervalReloadOrders = setInterval(() => {
      this.reloadCounterData();
    }, RELOAD_ORDERS_TIMER);
  }

  ngOnDestroy() {
    this.networkListener.remove();
    clearInterval(this.intervalLoadingRoles);
    clearInterval(this.intervalErrorlog);
    clearInterval(this.intervalReloadOrders);
  }

  /**
   * Eine Page öffnen
   */
  openPage(menuElement: ISideMenuElement): void {
    if (menuElement.onAction) {
      menuElement.onAction();
    }
    if (menuElement.routerLink) {
      if (
        this.pageService.deviceTypes.ios ||
        this.pageService.deviceTypes.android
      ) {
        this.ionicMenuComponent.close();
      }
      this.router.navigateByUrl(menuElement.routerLink);
    }
  }

  /**
   * Sichtbarkeit von Subelementen wechseln
   */
  changeSubElementsVisible(menuElement: ISideMenuElement): void {
    menuElement.subelementsVisible = !menuElement.subelementsVisible;
  }

  /**
   * Compare Methode für die Ion-Selects
   * @param o1 Option 1
   * @param o2 Option 2
   * @returns ob die Optionen gleich sind
   */
  compareWith(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  changedBusiness(): void {
    const success = this.userHelper.setBusiness(this.selectedBusiness);
    if (!success) {
      this.selectedBusiness = this.userHelper.getManBus().business;
    }
  }

  /**
   * Reloads data for the counters and rebuilds side-menu
   */
  async reloadCounterData(): Promise<void> {
    await this.ordersHelper.reloadServiceContracts();
    this.buildSideMenu();
    this.setAccess();
  }

  /**
   * Initializes the listener for new routes to display as current page
   */
  private initializeCurrentPageListener(): void {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe({
        next: (v: NavigationEnd) => {
          this.checkAndSetCurrentPage(v.urlAfterRedirects);
        },
      });
  }

  /**
   * Simplifys the url so no params or domain is present
   * @param url to simplify
   * @returns simplified url-string
   */
  private simplifyUrl(url: string): string {
    if (url.charAt(0) === '/') url = url.substring(1);
    return url.split('?', 1)[0];
  }

  /**
   * Checks and sets for same page url on an element
   * @param e Menu-Element to check
   * @param url to check for inside element
   */
  private checkSamePage(e: IMenuBasicElement, url: string) {
    if (this.checkForURLMatch(url, e)) {
      if (e.name) this.selectedPage = e.name;
    }
  }

  private checkElementForPage(elements: ISideMenuElement[], url: string) {
    elements.forEach((e) => {
      this.checkSamePage(e, url);
      if (e.subElements && e.subElements.length > 0) {
        this.checkElementForPage(e.subElements, url);
      }
    });
  }

  /**
   * Checks if the url is valid and sets the current page correctly
   * @param url URL to set for current page
   * @returns if side was found with this url
   */
  private checkAndSetCurrentPage(url: string): void {
    if (!this.sideMenu || !url) return;
    url = this.simplifyUrl(url);
    this.sideMenu.groups.forEach((g) => {
      this.checkElementForPage(g.elements, url);
    });
  }

  /**
   * Checks if URL is used inside the elements routerLink
   * @param url URL to check
   * @param element element to compare
   * @returns True / False
   */
  private checkForURLMatch(url: string, element: ISideMenuElement): boolean {
    if (!url || !element || !element.routerLink) return false;
    return element.routerLink.includes(url);
  }

  private initializeNetworkCheck() {
    Network.getStatus().then((status) => {
      this.isOnline = status.connected;
    });
    this.networkListener = Network.addListener(
      'networkStatusChange',
      (status) => {
        this.isOnline = status.connected;
      }
    );
  }

  private async configureApp() {
    await this.loadData();
    this._usesDamagereportsOnOrders =
      this.functionSwitchHelper.has(DAMAGE_REPORTS_ORDER);
    this._usesDamagereportsOnMachines = this.functionSwitchHelper.has(
      DAMAGE_REPORTS_MACHINE
    );
    if (!this.intervalErrorlog && this.userHelper.getUser()?.is_admin) {
      this.intervalErrorlog = setInterval(() => {
        this.errorLogHelper.reloadErrorlogs();
      }, 60000);
    } else {
      this.errorLogHelper.reloadErrorlogs();
    }
    this.buildSideMenu();
    this.setAccess();
    this.checkAndSetCurrentPage(this.router.url);
    this.appConfigured = true;
  }

  /**
   * Baut das Seitenmenü und setzt wenn möglich die Rechte
   */
  private async buildAndSetAccess(): Promise<void> {
    if (this.appConfigured) {
      await this.configureApp();
    } else {
      this.intervalLoadingRoles = setInterval(async () => {
        if (
          this.accessGuard.areRolesLoaded() &&
          this.functionSwitchHelper._functionSwitchesLoaded
        ) {
          await this.configureApp();
          this.ordersHelper.reloadServiceContracts();
          clearInterval(this.intervalLoadingRoles);
        }
      }, 2 * 1000);
    }
  }

  /**
   * Lädt alle für das Side-Menu wichtigen Daten
   * Wählt wenn vorhanden schon einen Mandanten und eine Betriebsstätte
   */
  private async loadData(): Promise<void> {
    const resUser = await this.userHelper.loadUser();
    this.selectedMandator = resUser.mandator;
    if (resUser.businesses?.length > 0) {
      this.selectedBusiness = resUser.businesses[0];
    }
  }

  /**
   * Funktion um den aktuellen Benutzer auszuloggen
   */
  private logout(): void {
    this.userHelper.logoutFull();
    this.keycloakService.logout(window.location.origin);
  }

  /**
   * Menü aufbauen
   */
  private buildSideMenu(): void {
    this.sideMenu = {
      headerTitle: 'Hauptmenü',
      groups: [
        {
          title: '',
          hide: true,
          elements: [
            {
              title: 'Startseite',
              icon: 'home-outline',
              routerLink: 'dashboard-worker',
              name: 'dashboard-worker',
              offlineSupported: true,
              accessGranted: false,
            },
          ],
        },
        {
          title: 'Bearbeitung',
          hide: true,
          elements: [
            {
              title: 'Aktive Aufträge',
              icon: 'document-text-outline',
              routerLink: 'orders/active',
              name: 'orders-active',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                text: this.ordersHelper.getCounters().activeCount,
              },
            },
            {
              title: 'Serviceaufträge',
              icon: 'document-text-outline',
              routerLink: 'orders/overview',
              name: 'orders-overview',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                text: this.ordersHelper.getCounters().openCount,
              },
            },
            {
              title: 'Auftrag erstellen',
              icon: 'document-attach-outline',
              routerLink: 'emergency-order/create',
              name: 'order-create-emergency',
              offlineSupported: true,
              accessGranted: false,
            },
          ],
        },
        {
          title: 'Stammdaten',
          hide: true,
          elements: [
            {
              title: 'Kunden',
              icon: 'people-outline',
              routerLink: 'customers',
              name: 'customers',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Maschinen',
              icon: 'cafe-outline',
              routerLink: 'machines',
              name: 'machines',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Autos',
              icon: 'car-sport-outline',
              routerLink: 'cars',
              name: 'cars',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Schadensmeldungen',
              icon: 'trash-bin-outline',
              routerLink: 'damageManagement/damage_reports',
              name: 'damage_reports',
              offlineSupported: true,
              accessGranted: false,
              hidden: !(
                this._usesDamagereportsOnMachines ||
                this._usesDamagereportsOnOrders
              ),
            },
          ],
        },
        {
          title: 'Backend',
          hide: true,
          elements: [
            {
              title: 'Dashboard',
              icon: 'apps-outline',
              routerLink: 'dashboard-inside',
              name: 'dashboard-inside',
              accessGranted: false,
            },
            {
              title: 'Serviceaufträge',
              icon: 'document-text-outline',
              routerLink: 'service_contracts',
              name: 'service-contracts',
              accessGranted: false,
            },
            {
              title: 'Einstellungen',
              icon: 'cog-outline',
              routerLink: 'settings',
              name: 'settings',
              accessGranted: false,
            },
            {
              title: 'Checklisten',
              icon: 'list-outline',
              routerLink: 'checklists',
              name: 'checklists',
              accessGranted: false,
            },
            {
              title: 'Templates',
              icon: 'brush-outline',
              routerLink: 'templates',
              name: 'templates',
              accessGranted: false,
            },
          ],
        },
        {
          title: 'Optionen',
          cssClass: 'bottom',
          elements: [
            {
              title: 'Hilfe',
              icon: 'help-outline',
              routerLink: 'help',
              name: 'help',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Warteschlange',
              icon: 'cloud-upload-outline',
              routerLink: 'dashboard-worker',
              name: 'dashboard-worker',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Abmelden',
              icon: 'log-out-outline',
              color: 'danger',
              offlineSupported: true,
              accessGranted: true,
              onAction: () => {
                this.logout();
              },
            },
          ],
        },
      ],
    };
  }

  /**
   * Setzt Berechtigungen im Menü
   */
  private setAccess(): void {
    this.sideMenu.groups.forEach((group) => {
      group.elements.forEach((element) => {
        this.accessGuard.hasAccessToPath(element.routerLink).then((res) => {
          element.accessGranted = res;
          if (element.accessGranted) {
            group.hide = false;
          }
        });
      });
    });
  }
}
