import { Component, Input } from '@angular/core';

export type MitsBadgeColor =
  | 'primary'
  | 'tertiary'
  | 'warning'
  | 'danger'
  | 'success';

@Component({
  selector: 'mits-badge',
  templateUrl: './mits-badge.component.html',
  styleUrls: ['./mits-badge.component.scss'],
})
export class MitsBadgeComponent {
  /** Value of the badge */
  @Input() value: string | number = '0';
  /** Color of the badge */
  @Input() color: MitsBadgeColor = 'primary';

  /**
   * Is the current color equal the passed color
   * @param c color to check
   * @returns if colors are the same
   */
  isColor(c: MitsBadgeColor): boolean {
    return this.color === c;
  }
}
