import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ArrayHelper } from 'src/packages/mitsBasics';
import { EndpointService } from 'src/packages/mitsBasics/endpoint.service';
import { DamagePositionModel, DamageReportModel, DAMAGE_STATE_FINISHED } from '../models';
import { DamageReportService } from './damage-report.service';

@Injectable({
  providedIn: 'root',
})
export class DamageService extends EndpointService {


  /**
   * Lädt einen spezifischen Schaden aus einem DamageReport
   * @param {number} damageReportId 
   * @param {number} damageId 
   * @return {Promise<DamagePositionModel>}
   */
  public async loadDamageFromReport(damageReportId: number, damageId: number): Promise<DamagePositionModel> {
    const report = await firstValueFrom(this.reportService.find(damageReportId));

    return report.damages.find((damage) => damage.id === damageId);
  }

  constructor(
    private reportService: DamageReportService
  ) {
    super();
  }


  /**
   * Speichert einen bestehenden Schaden über den Schadensbericht
   * @param {DamagePositionModel} damage 
   * @return {Promise<DamageReportModel>}
   */
  public async save(damage: DamagePositionModel): Promise<DamageReportModel> {
    const report = await firstValueFrom(this.reportService.find(damage.damage_report_id));

    let damageIndex = report.damages.findIndex((d) => d.id === damage.id)

    ArrayHelper.insertOrUpdate(
      report.damages,
      damage,
      damageIndex
    );

    report.updated_at = undefined;
    await this.reportService.saveLocal(report);

    return firstValueFrom(this.reportService.save(report));
  }

  /**
   * Schäden für eine Maschine laden
   * @param {number} machineId
   * @return {Promise<DamagePositionModel[]>}
   */
  public async loadOpenDamagesForMachine(machineId: number): Promise<DamagePositionModel[]> {
    let damages : DamagePositionModel[] = [];
    const reports = await this.reportService.localWhereWithoutPaging({
      machine_id: machineId
    });

    reports.forEach((report) => {
      damages = damages.concat(this.openDamages(report.damages))
    })

    return damages;
  }


  /**
   * Schäden nach dem Status nicht fertig filtern
   * @param {DamagePositionModel[]} damages
   * @return {DamagePositionModel[]}
   */
  private openDamages(damages: DamagePositionModel[]): DamagePositionModel[] {
    return damages
      .filter((damage) => damage.state !== DAMAGE_STATE_FINISHED);
  }

}
