import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { ArticleModel } from "src/app/models/articles/article";
import { CarInventoryModel } from "src/app/models/cars/inventory/car-inventory";
import { CarInventoryService } from "src/app/providers/model-services/cars/car-inventory.service";
import { GlobalHelper } from "src/packages/mitsBasics/GlobalHelper";

export type InventoryType = "inventory_articles" | "disposal_articles";

const LOCALSTROAGE_KEY = "CAR_STORAGE";

@Injectable({
  providedIn: "root",
})
export class InventoryHelper {
  constructor(private readonly carInventoryService: CarInventoryService) {}

  /**
   * Locales Inventory vorhanden?
   */
  public hasLocalInventory(): boolean {
    return !!this.localInventory;
  }

  /**
   * Remote Inventory laden
   */
  public getRemoteInventory(): Observable<CarInventoryModel> {
    return this.carInventoryService.getInventory();
  }

  /**
   * Überprüft ob ein Artikel geladen ist
   * @param {ArticleModel} article
   * @return {boolean}
   */
  public isLoaded(article: ArticleModel): boolean {
    const articleInv = this.localInventory.inventory_articles.filter(
      (i) => i.article_id === article.id
    )[0];

    if(articleInv) {
      return articleInv.amount > 0
    } else {
      return false;
    }
  }

  /**
   * Gibt die Menge eines Artikles zurück im Inventar des Autos
   */
  public getCurrentAmount(articleId: number): number {
    const inventory = this.localInventory;
    const articleInv = inventory?.inventory_articles?.find(
      (i) => i.article_id === articleId
    );
    return articleInv ? articleInv.amount : 0;
  }

  /**
   * Bulk Inventarverbuchung für eine Combined Postion
   */
  public updateInventoryBulk(
    articleId: number,
    amount: number,
    takeBack: number,
    disposalAmount: number
  ) {
    // Normale Bestandsveränderung
    this.updateInventory(articleId, +amount);
    // Rücknahmen auf Lager buchen
    this.updateInventory(articleId, +takeBack * -1);
    // Schrott aufs Lager buchen
    this.updateDisposal(articleId, +disposalAmount);
  }

  /**
   * Verbuchungen vom normalen Lagerbestand
   */
  public updateInventory(articleId: number, amount: number) {
    this.updateInventoryInternal(articleId, amount, "inventory_articles");
  }

  /**
   * Verbuchungen von Entsorgungen
   */
  public updateDisposal(articleId: number, amount: number) {
    this.updateInventoryInternal(articleId, amount, "disposal_articles");
  }

  /**
   * Lokales Repository aktualisieren
   */
  public async updateLocalInventory(force: boolean = false) {
    const inventory = await firstValueFrom(this.getRemoteInventory());

    if (this.localInventory && inventory) {
      if (
        force ||
        GlobalHelper.dateGreaterEquals(
          inventory.updated_at as string,
          this.localInventory.updated_at as string
        )
      ) {
        this.localInventory = inventory;
      } else {
        console.warn(
          "Lokales Inventar nicht geupdated, da lokaler Zeitstempel neuer"
        );
      }
    } else {
      this.localInventory = inventory;
    }
  }

  /**
   * Getter Locales Inventory
   */
  private get localInventory(): CarInventoryModel {
    return JSON.parse(localStorage.getItem(LOCALSTROAGE_KEY));
  }

  /**
   * Setter Locales Inventory
   */
  private set localInventory(v: CarInventoryModel) {
    localStorage.setItem(LOCALSTROAGE_KEY, JSON.stringify(v));
  }

  /**
   * Lokales Fahrzeuglager aktualisieren
   */
  private updateInventoryInternal(
    articleId: number,
    amount: number,
    type: InventoryType
  ) {
    const inventory = this.localInventory;
    const position = inventory[type].find((i) => i.article_id === articleId);
    if (position) {
      position.amount -= amount;
    } else {
      inventory[type].push({ article_id: articleId, amount: amount * -1 });
    }
    inventory.updated_at = new Date().toISOString();
    this.localInventory = inventory;
  }
}
