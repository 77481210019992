/**
 * System
 */
export const CAR_MANAGEMENT = 'CarManagement';
export const SERVICE_ORDERS = 'Serviceaufträge';
export const ONLY_NATIVE_SCANNER =
  'Nur native Barcodescanner Nutzung erlauben?';
export const OFFLINE_STORAGE_UPDATE_INTERVAL =
  'Abrufintervale für den offline Speicher';

/**
 * Benutzerkonten
 */
export const CHANGEABLE_EMPLOYEE_ID = 'Personalnummern änderbar?';
export const CHANGEABLE_PASSWORD = 'Passwort änderbar?';

/**
 * Montagebericht
 */
export const REPORT_SHOW_DISTANCE = 'Gefahrene Kilometer auf Bericht Rendern';

/**
 * CarManagement
 */
export const START_END_MILEAGE_CARS =
  'Sollen start und end Kilometerstände erfasst werden?';
export const CONFIRMATION_POSITION_CAR_ORDERS =
  'Bestätigung pro Position am Auto benötigt?';
export const NEGATIVE_POSITIONS_CARS =
  'Negativbestände auf Fahrzeugen möglich?';
export const DISABLE_STORNO_OF_CAR_ORDERS =
  'Keine Stornierung von Bestellungen möglich';
export const LOADING_ORDERS_AMOUNT_CHANGES =
  'Beladen nach Bestellungen - Mengenänderung durch Außendienst erlauben';
export const UNLOADING_LOAD_ARTICLES =
  'Fahrzeug Entladen - Artikel vom Fahrzeug vorausfüllen';
export const UNLOADING_SEGMENT_LOADING_ACTIVE =
  'Fahrzeug Entladen - Segment "Beladen" anzeigen';
export const CARS_MIN_MINUTES_BETWEEN_RELOADS =
  'Fahrzeuge erst ab X Minuten erneut aus dem Backend aktualisieren';

/**
 * Machine
 */
export const MACHINE_PLANOGRAMS =
  'Können an Maschinen Planogramme hinterlegt werden?';
export const MACHINE_CUSTOMERNUMBER_ONLY_CREATE =
  'Kundennummer nur beim Erstellen frei zur Eingabe?';
export const MACHINE_DISPLAY_REFERENCE = 'Referenz anzeigen';
export const MACHINE_ALLOW_SCAN_OF_SCAN_CODE =
  'Scanerfassung Scan-Code erlauben';
export const MACHINE_ALLOW_SCAN_OF_SERIALNUMBER =
  'Scanerfassung Seriennummer erlauben';

/**
 * Artikel
 */
export const ARTICLE_OVERPACK_ACTIVE = 'Artikelumverpackungen aktivieren?';
export const ARTICLE_SELECTION_SEARCH_SHOULD_STAY =
  'Artikelauswahl soll die Sucheingabe nach Auswahl eines Artikels beibehalten';
export const ARTICLE_ENABLE_TAKEBACK = 'Rücknahme von Artikeln';
export const ARTICLE_ENABLE_DISPOSAL = 'Entsorgung von Artikeln';
export const ARTICLE_SCAN_AMOUNT = 'Menge beim Scannen mit x vorbesetzen';

/**
 * Schadenserfassung
 */
export const DAMAGE_REPORTS_ORDER = 'Schadenserfassung an Aufträgen';
export const DAMAGE_REPORTS_MACHINE = 'Schadenserfassung an Maschinen';
export const DAMAGE_REPORTS_LISTED_MASTERDATA =
  'Schadensmeldungen als Übersicht unter Stammdaten';
export const DAMAGE_REPORTS_SIGNATURES =
  'Unterschriften unter Schadensmeldungen';
export const DAMAGE_REPORTS_SHOW_PDF =
  'PDF der Schadensmeldungen nach Abschluss darstellen';

/**
 * ChecklistManagement
 */
export const CHECKLISTS_SIGNATURES_NEEDED =
  'Unterschriften in Checklisten benötigt?';
export const CHECKLISTS_RENDER_PDF =
  'Zeige Checkliste als PDF dem Benutzer an?';
export const CHECKLISTS_SIGNATURE_TEXT_CUSTOMER_NUMBER =
  'Kundennummer unter Unterschrift (Auftraggeber) vorausfüllen';
export const CHECKLISTS_NO_SIGNATURE_CUSTOMER_IF_NAMED =
  'Unterschrift (Auftraggeber) entfällt wenn Auftraggeber-Name ausgefüllt';
export const CHECKLISTS_CUSTOMER_SIGNATURE_ONLY_TECHNICAL =
  'Unterschrift (Auftraggeber) nur wenn Reparaturen durch Techniker';

/**
 * Serviceaufträge
 */
export const USER_CAN_ADD_ONLY_ONE_MACHINE =
  'Maximal eine Maschine pro Auftrag';
export const CAN_ADD_MACHINE =
  'Aussendienst kann neue Maschinen im Auftrag hinzufügen';
export const ONLY_SCAN_MACHINE = 'Auftragsmaschine nur Scanbar';
export const DISTANCE_EDITABLE = 'Gefahrene Kilometer veränderbar Aussendienst';
export const TIME_EDITABLE = 'Erfasste Arbeitszeit veränderbar Aussendienst';
export const SHOW_ARTICLE_AMOUNT_POSITIONS =
  'Gesamtmenge aller Artikel an allen Positionen einer Maschine zeigen';
export const CAN_CANCEL_REPORT_WHEN_FINISHED =
  'Abbrechen einer Maschine möglich wenn diese schon Abgeschlossen wurde?';
export const SHOW_ORDERNUMBER_IN_OVERVIEW =
  'Auftragsnummer in Auftragsübersicht anzeigen';
export const SHORTEN_ORDERNUMBER_IN_OVERVIEW =
  'Auftragsnummer in Auftragsübersicht abkürzen';
export const SHOW_CANCEL_ORDER_ALERT_ON_SIGNOUT =
  'Abbrechen von Aufträgen ermöglichen beim Abmelden vom Fahrzeug';
export const SA_SIGNATURE_TEXT_CUSTOMER_NUMBER =
  'Kundennummer unter Unterschrift (Auftraggeber) vorausfüllen (SA)';
export const SA_NO_SIGNATURE_CUSTOMER_IF_NAMED =
  'Unterschrift (Auftraggeber) entfällt wenn Auftraggeber-Name ausgefüllt (SA)';
export const SA_CUSTOMER_SIGNATURE_ONLY_TECHNICAL =
  'Unterschrift (Auftraggeber) nur wenn Reparaturen durch Techniker (SA)';
export const SA_SIGNATURES_NEEDED =
  'Unterschriften in Serviceauftrag benötigt?';
export const SA_CONFIRM_ORDER_COMPLETION =
  'Auftragsende nach Unterschrift bestätigen?';
export const SA_RENDER_PDF_BEFORE_SIGN =
  'Zeige Serviceauftrag vor der Unterschrift als PDF dem Benutzer an?';
export const SA_RENDER_PDF_AFTER_SIGN =
  'Zeige Serviceauftrag nach der Unterschrift als PDF dem Benutzer an?';
export const SA_SEGMENT_DONE_ACTIVE =
  'Zeige das Segment "Erledigt" in der Auftragsübersicht an?';
