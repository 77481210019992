import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { CarOrderModel } from 'src/app/models/cars/car-order';
import { ResponseModel } from 'src/app/models/response';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class CarOrderService extends OfflineDataService<CarOrderModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'CarManagement::Order',
      http,
      errorService,
      events,
      'car_management/orders/',
      'order',
      ['customer'],
      []
    );
  }

  /**
   * Erhält alle Objecte vom Endpunkt.
   */
  public all(): Observable<ResponseModel<CarOrderModel>> {
    if (navigator.onLine) {
      return super.allRemote();
    } else {
      return super.all();
    }
  }

  /**
   * Sucht Objekte mit Argumenten
   * @param args
   */
  where(args): Observable<ResponseModel<CarOrderModel>> {
    if (navigator.onLine) {
      return super.whereRemote(args);
    } else {
      return super.where(args);
    }
  }

  /**
   * Ruft ein Objekt mit Details ab
   * @param id
   */
  findRemote(id): Observable<CarOrderModel> {
    if (navigator.onLine) {
      return super.findRemote(id);
    } else {
      return super.find(id);
    }
  }

  /**
   * Löscht ein Objekt im Backend
   * @param id
   */
  delete(id): Observable<CarOrderModel> {
    return new Observable((subscriber) => {
      if (id < 0) {
        this.localDelete(id);
        this.queueService
          .getQueuedData()
          .then((data) => {
            data
              .filter(
                (item) => item.entityId === id && item.entityType === this.type
              )
              .forEach((item) => {
                this.queueService
                  .delete(item.id)
                  .then(() => {
                    subscriber.next(null);
                    subscriber.complete();
                  })
                  .catch((ex) => {
                    console.error(ex);
                    subscriber.next(null);
                    subscriber.complete();
                  });
              });
            subscriber.next(null);
            subscriber.complete();
          })
          .catch((err) => {
            console.error(err);
            subscriber.next(null);
            subscriber.complete();
          });
      } else {
        this.dataService.delete(id).subscribe((result) => {
          this.localDelete(id);
          subscriber.next(result);
          subscriber.complete();
        });
      }
    });
  }
}
