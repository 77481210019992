import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { MandatorModel } from 'src/app/models/settings/mandator';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class MandatorService extends OfflineDataService<MandatorModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Mandator',
      http,
      errorService,
      events,
      'mandators/',
      'mandator',
      [
        'created_by_id',
        'created_at',
        'updated_at',
        'header64',
        'logo64',
        'footer64',
        'attachments',
      ],
      []
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'walk';
  }

  /**
   * Auf dem Server speichern
   * @param {MandatorModel} mandator
   * @return {Observable<MandatorModel>}
   */
  saveRemote(mandator: MandatorModel): Observable<MandatorModel> {
    // TODO es sollte das Mandatormodel noch überarbeitet werden, da dies hier ein dirty fix ist.
    // dafür muss aber noch Details angepasst werden, da es dann kein Array mehr ist.
    let keys = ['logo', 'report_header', 'report_footer'];

    keys.forEach((key) => {
      let element = mandator[key];

      if (element && element.length > 0) mandator[key] = element[0];
    });

    if (mandator.id >= 0) {
      return this.http
        .put<MandatorModel>(
          this.endpointWithUrl + mandator.id,
          toFormData(mandator, this, ['logo', 'report_header', 'report_footer'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<MandatorModel>(
          this.endpointWithUrl,
          toFormData(mandator, this, ['logo', 'report_header', 'report_footer'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }
}
