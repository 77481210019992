import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CarInventoryModel } from 'src/app/models/cars/inventory/car-inventory';
import { DataService } from '../../data.service';

@Injectable({
  providedIn: 'root',
})
export class CarInventoryService extends DataService<CarInventoryModel> {
  endpoint = 'car_management/movement/inventory';
  objectName = 'base_movement';

  removeParams = ['client_ok', 'article_name'];

  /**
   * Loads the inventory of the currently signed in car
   * @returns Obserable of backend response
   */
  getInventory(): Observable<CarInventoryModel> {
    return this.http
      .get<any>(this.endpointWithUrl)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
