import { EventEmitter, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import {
  SyncEngineService,
  SyncInformationService,
  SyncProcessorService,
  SyncQueueService,
} from '@vending/sync-engine-client/dist/sync-engine-client';
import { QueueEntry } from '@vending/sync-engine-client/dist/sync-engine-client/lib/models/queueEntry';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { CarService } from 'src/app/providers/model-services/cars/car.service';
import { MileageService } from 'src/app/providers/model-services/orders/mileage.service';
import { ChecklistService } from 'src/packages/checklistManagement/providers/checklist.service';
import {
  DamageCategoryService,
  DamageReportService,
} from 'src/packages/damageManagement/providers';
import { TemplateService } from 'src/packages/templateManagement/providers/template.service';
import { OFFLINE_STORAGE_UPDATE_INTERVAL } from '../functionSwitch.constants';
import { DataService } from './data.service';
import { InventoryHelper } from './helpers/inventory-helper.service';
import { UserHelper } from './helpers/user-helper.service';
import { ArticleUnitService } from './model-services/article-unit.service';
import { ArticleService } from './model-services/article.service';
import { BoilerplateService } from './model-services/boilerplate.service';
import { BusinessService } from './model-services/business.service';
import { CarOrderService } from './model-services/cars/car-order.service';
import { ClientDeviceService } from './model-services/client-device.service';
import { CustomerService } from './model-services/customer.service';
import { MachineGroupService } from './model-services/machines/machine-group.service';
import { MachineStateService } from './model-services/machines/machine-state.service';
import { MachineTypeService } from './model-services/machines/machine-type.service';
import { MachineService } from './model-services/machines/machine.service';
import { MandatorService } from './model-services/mandator.service';
import { OrderService } from './model-services/orders/order.service';
import { TimelogService } from './model-services/orders/timelog.service';
import { PriorityService } from './model-services/priority.service';
import { StatusService } from './model-services/status.service';
import { UserService } from './model-services/user.service';
import { TemporaryImageService } from './model-services/writeable-checklist/temporary-image.service';
import { OfflineDataService } from './offlineData.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Wrapper bzw. Facade der alles für Offline kapselt
 */
export class SyncWrapperService {
  private _newVersion: boolean = false;

  public get newVersion(): boolean {
    return this._newVersion;
  }

  public set newVersion(v: boolean) {
    this._newVersion = v;
  }

  public get icons(): Record<string, string> {
    return this._icons;
  }

  private set icons(v: Record<string, string>) {
    this._icons = v;
  }

  constructor(
    private readonly syncInformationService: SyncInformationService,
    private readonly engineService: SyncEngineService,
    private readonly checklistService: ChecklistService,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly invHelper: InventoryHelper,
    private readonly loadingCtrl: LoadingController,
    private readonly syncProcessor: SyncProcessorService,
    private readonly userHelper: UserHelper,
    // Hier müssen weitere offline verfügbare Dienste injiziert werden, damit diese im ProcessorService registriert sind
    public readonly customerService: CustomerService,
    public readonly machineService: MachineService,
    public readonly mandatorService: MandatorService,
    public readonly businessService: BusinessService,
    public readonly userService: UserService,
    public readonly unitService: ArticleUnitService,
    public readonly damageCategoryService: DamageCategoryService,
    public readonly prioritySerivce: PriorityService,
    public readonly clientDeviceService: ClientDeviceService,
    public readonly damageReportService: DamageReportService,
    public readonly boilerplateService: BoilerplateService,
    public readonly orderService: OrderService,
    public readonly articleService: ArticleService,
    public readonly temporaryImageService: TemporaryImageService,
    public readonly mileageService: MileageService,
    public readonly indexedDBService: NgxIndexedDBService,
    public readonly machineGroupService: MachineGroupService,
    public readonly machineTypeService: MachineTypeService,
    public readonly machineStateService: MachineStateService,
    public readonly carService: CarService,
    public readonly statusService: StatusService,
    public readonly templateService: TemplateService,
    public readonly timelogService: TimelogService,
    public readonly carOrderService: CarOrderService
  ) {
    SyncInformationService.setServiceUrl(DataService.serviceUrl);
    this.addService(this.mandatorService);
    this.addService(this.businessService);
    this.addService(this.userService);
    this.addService(this.clientDeviceService);
    this.addService(this.unitService);
    this.addService(this.prioritySerivce);
    this.addService(this.damageCategoryService);
    this.addService(this.damageReportService);
    this.addService(this.customerService);
    this.addService(this.machineService);
    this.addService(this.boilerplateService);
    this.addService(this.articleService);
    this.addService(this.orderService);
    this.addService(this.temporaryImageService);
    this.addService(this.mileageService);
    this.addService(this.checklistService);
    this.addService(this.machineGroupService);
    this.addService(this.machineTypeService);
    this.addService(this.machineStateService);
    this.addService(this.carService);
    this.addService(this.statusService);
    this.addService(this.templateService);
    this.addService(this.timelogService);
    this.addService(this.carOrderService);
  }

  /**
   * Letzter Sync Timestamp
   * @returns sync time
   */
  public get syncTimestamp(): Date | null {
    return this.syncProcessor.lastSyncTimestamp;
  }
  private syncStarted = false;
  private syncInterval: number = 60 * 1000;

  private services: Array<OfflineDataService<any>> = [];

  private initialLoadingOverlay;

  private _icons: Record<string, string> = {};

  public downloadStatusChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public uploadStatusChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Fügt intern den Service ein
   * @param service to add
   */
  private addService(service: OfflineDataService<any>) {
    this.services.push(service);
    this.icons[service.type] = service.iconName;
  }

  /**
   * queuedData
   */
  public queuedData(): Promise<QueueEntry<any>[]> {
    const service = new SyncQueueService(this.indexedDBService, '');
    return service.getQueuedData();
  }

  /**
   * Update queue entry
   */
  public updateQueueEntry(entry: QueueEntry<any>): Promise<number> {
    const service = new SyncQueueService(this.indexedDBService, '');
    return service.update(entry);
  }

  /**
   * Starte die Verarbeitung der Daten
   */
  public async startSync() {
    // Serverseitige Synchronisierung starten
    this.syncStarted = true;

    this.syncProcessor.fullSyncStarted.subscribe((res) => this.initLoading());
    this.syncProcessor.fullSyncProgess.subscribe((val) => this.setProgess(val));
    this.syncProcessor.fullSyncStopped.subscribe((val) => this.stopLoading());

    await this.internalSync();

    await this.invHelper.updateLocalInventory();

    const fsIntervalOffline = await this.functionSwitchHelper.getValue(
      OFFLINE_STORAGE_UPDATE_INTERVAL
    );
    if (Number(fsIntervalOffline) > 0) {
      this.syncInterval = Number(fsIntervalOffline) * 1000;
    }

    this.internalSync().then(() => {
      if (Number(fsIntervalOffline) > 0)
        interval(this.syncInterval)
          .pipe(takeWhile(() => this.syncStarted))
          .subscribe(async () => await this.internalSync());
    });

    // Updates von Client-Seite aus starten (Queue)
    this.services.forEach((service) => {
      service.startSync();
    });
  }

  /**
   * Löscht die lokalen IndexDB und lädt die Anwendung neu
   */
  public async dropSyncInformationsAndReload() {
    // Sync anhalten
    this.stopSync();

    // Zeitstempel vor Host Veränderung zurücksetzen, da danach nicht mehr möglich
    this.syncInformationService.clearTimestamp();
    window.location.href = '/';

    // Indexdb komplett löschen
    await this.engineService.resetAll();
  }

  /**
   * Sync Fenster Loading starten
   */
  public async initLoading() {
    this.initialLoadingOverlay = await this.loadingCtrl.create({
      message: 'Initiale Synchronisation...',
      duration: 0,
    });

    this.initialLoadingOverlay.present();
  }

  /**
   * Fortschritt im Loading-Fenster setzen
   * @param val progress
   */
  private setProgess(val: number) {
    if (this.initialLoadingOverlay) {
      this.initialLoadingOverlay.message = `Initiale Synchronisation:<br/> ${
        Math.round(val * 100) / 100
      } %`;
    }
  }

  /**
   * Initial Sync Fenster schleißen
   */
  private stopLoading() {
    if (this.initialLoadingOverlay) {
      this.initialLoadingOverlay.dismiss();
      this.userHelper.loadUser();
    }
  }

  /**
   * Interne Synchronisierungsfunktion, um den Prozess zu starten
   */
  public async internalSync() {
    const args = {
      user_id: this.userHelper.getUser().id,
    };
    this.downloadStatusChanged.emit(true);
    await this.syncProcessor.process(args);
    this.downloadStatusChanged.emit(false);
  }

  public async startUploadedQueue() {
    this.uploadStatusChanged.emit(true);
    this.orderService.startSyncOnce();
    this.uploadStatusChanged.emit(false);
  }

  /**
   * Synchronisierung stoppen
   */
  public stopSync() {
    this.syncStarted = false;

    this.services.forEach((service) => {
      service.stopSync();
    });
  }
}
