import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { CarModel } from 'src/app/models/cars/car';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { InventoryHelper } from '../../helpers/inventory-helper.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class CarService extends OfflineDataService<CarModel> {
  /**
   * HTTP Options
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    private readonly invHelper: InventoryHelper
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'CarManagement::Car',
      http,
      errorService,
      events,
      'car_management/cars/',
      'car',
      [
        'deleted_at',
        'created_by_id',
        'current_user_info',
        'inventory_articles',
        'loadlist_articles',
        'loadlists',
        'created_at',
        'updated_at',
        'remote_id',
      ],
      [],
      'ean'
    );
  }

  /**
   * Signing in on a car
   * @param carId ID of selected car
   * @returns Backend response as an observable
   */
  signIn(car: CarModel): Observable<CarModel> {
    return this.http
      .get<CarModel>(
        this.endpointWithUrl + 'signin/' + car.id,
        this.httpOptions
      )
      .pipe(tap((x) => this.afterSaveCallback(x)))
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Signing out of a car
   * @param car is the selected car
   * @returns Backend response as an observable
   */
  signOut(car: CarModel): Observable<CarModel> {
    car.current_user_id = null;
    return this.http
      .post<CarModel>(
        this.endpointWithUrl + 'signout',
        this.formatPayload(car),
        this.httpOptions
      )
      .pipe(tap((x) => this.afterSaveCallback(x)))
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Nach dem Speichern wird der Callback ausgeführt
   * @param car wich got returned after save
   */
  private async afterSaveCallback(car: CarModel) {
    await this.saveLocal(car);
    await this.invHelper.updateLocalInventory(true);
  }

  /**
   * Signing out of a car
   * @param carId ID of selected car
   * @returns Backend response as an observable
   */
  completion(): Observable<CarModel> {
    return this.http
      .get<CarModel>(this.endpointWithUrl + 'completion/', this.httpOptions)
      .pipe(retry(1));
  }
}
