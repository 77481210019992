import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '../providers/component-helpers/toast.service';
import { EventsService } from '../providers/events.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private alreadyShowedAuthError = false;

  constructor(
    private events: EventsService,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${currentUser.token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.handleAuthError();
          }
        }
      )
    );
  }

  /**
   * Display Toast that the user is not logged in anymore
   */
  private handleAuthError() {
    if (!this.alreadyShowedAuthError) {
      this.alreadyShowedAuthError = true;
      this.toastService.custom(
        'Die Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
        'danger',
        0,
        [
          {
            text: 'Weiterarbeiten',
            role: 'cancel',
            handler: () => {
              setTimeout(() => {
                this.alreadyShowedAuthError = false;
              }, 30 * 1000);
            },
          },
          {
            text: 'Abmelden',
            role: 'submit',
            handler: () => {
              this.events.publish('logout', null);
              this.alreadyShowedAuthError = false;
            },
          },
        ]
      );
    }
  }
}
