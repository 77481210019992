import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DAMAGE_MANAGEMENT_BASE_ROUTE } from '../packages/damageManagement/damageManagement-routing.module';
import { CanDeactivateGuard } from './guards/canDeactivate.guard';
import { AccessGuard } from './services/access.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard-worker',
    pathMatch: 'full',
  },
  {
    path: 'statusboard',
    loadChildren: () =>
      import('./pages/backend/statusboard/statusboard.module').then(
        (m) => m.StatusboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'dashboard-inside',
    loadChildren: () =>
      import('./pages/backend/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'machines',
    loadChildren: () =>
      import('./pages/masterdata/machines/machines.module').then(
        (m) => m.MachinesPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./pages/options/help/help.module').then((m) => m.HelpPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/backend/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./pages/processing/orders/orders.module').then(
        (m) => m.OrdersPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'emergency-order/:id',
    loadChildren: () =>
      import('./pages/processing/emergency-order/emergency-order.module').then(
        (m) => m.EmergencyOrderPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'service_contracts',
    loadChildren: () =>
      import('./pages/backend/service-contracts/service-contracts.module').then(
        (m) => m.ServiceContractsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'cars',
    loadChildren: () =>
      import('./pages/masterdata/cars/cars.module').then(
        (m) => m.CarsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'checklists',
    loadChildren: () =>
      import(
        '../packages/checklistManagement/pages/checklists/checklists.module'
      ).then((m) => m.ChecklistsPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import(
        '../packages/templateManagement/pages/templates/templates.module'
      ).then((m) => m.TemplatesPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/masterdata/customers/customers.module').then(
        (m) => m.CustomersPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'mits-scanner-modal',
    loadChildren: () =>
      import(
        './components/mits-scanner-button/mits-scanner-modal/mits-scanner-modal.module'
      ).then((m) => m.MitsScannerModalPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'dashboard-worker',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: DAMAGE_MANAGEMENT_BASE_ROUTE,
    loadChildren: () =>
      import(
        '../packages/damageManagement/damageManagement-routing.module'
      ).then((m) => m.DamageManagementRoutingModule),
    canActivate: [AccessGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [CanDeactivateGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
