<ion-app>
  <ion-split-pane contentId="main" when="lg">
    <ion-menu #ionicMenu contentId="main" menuId="mainMenu">
      <ion-header style="padding: 4px">
        <app-logo></app-logo>
      </ion-header>
      <ion-content *ngIf="sideMenu && userHelper.getManBus()">
        <ion-item>
          <ion-label position="floating">Betriebsstätte</ion-label>
          <ion-select [(ngModel)]="selectedBusiness" [compareWith]="compareWith"
            [disabled]="userHelper.getUser()?.businesses?.length <= 1" (ionChange)="changedBusiness()"
            okText="Auswählen" cancelText="Abbrechen">
            <ion-select-option *ngFor="let business of userHelper.getUser()?.businesses" [value]="business">
              {{ business.name1 }} {{ business.name2 }} {{ business.name3 }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div class="{{ group.cssClass }}" *ngFor="let group of sideMenu.groups">
          <span *ngIf="!group.hide">
            <ion-item class="menu-heading" *ngIf="group.title" [title]="group.title" expand="full" lines="full">
              <span class="menu-heading" expand="full">
                {{ group.title }}
              </span>
            </ion-item>
            <div *ngFor="let element of group.elements" [title]="element.title">
              <ion-item *ngIf="
                  element.accessGranted &&
                  (element.offlineSupported || isOnline) &&
                  !element.subElements && !element.hidden
                " [ngClass]="{
                  active: element.name && this.selectedPage === element.name
                }" [title]="element.title" (click)="openPage(element)" lines="full">
                <ion-icon [name]="element.icon" [color]="
                    element.name && this.selectedPage === element.name
                      ? 'primary'
                      : element.color
                  ">
                </ion-icon>
                <ion-label [color]="element.color"> {{ element.title }} </ion-label>
                <mits-badge *ngIf="element.badge" [color]="element.badge.color" [value]="element.badge.text" slot="end">
                </mits-badge>
              </ion-item>
              <div *ngIf="element.subElements">
                <ion-item *ngIf="
                    element.accessGranted && !element.hidden &&
                    (element.offlineSupported || isOnline)
                  " [ngClass]="{
                    active: element.name && this.selectedPage === element.name
                  }" [title]="element.title" (click)="changeSubElementsVisible(element)" lines="full">
                  <ion-icon [name]="element.icon" [color]="
                      element.name && this.selectedPage === element.name
                        ? 'light'
                        : element.color
                    "></ion-icon>
                  <ion-label class="ion-padding" [color]="element.color"> {{ element.title }} </ion-label>
                  <ion-icon class="colorGrey" *ngIf="!element.subelementsVisible" name="arrow-down" slot="end">
                  </ion-icon>
                  <ion-icon class="colorGrey" *ngIf="element.subelementsVisible" name="arrow-up" slot="end"></ion-icon>
                </ion-item>
                <div class="menuSubList" *ngIf="element.subelementsVisible">
                  <ng-container *ngFor="let subElement of element.subElements">
                    <ion-item *ngIf="
                        subElement.accessGranted && !subElement.hidden &&
                        (element.offlineSupported || isOnline)
                      " [ngClass]="{
                        active:
                          subElement.name && selectedPage === subElement.name
                      }" [title]="subElement.title" (click)="openPage(subElement)" lines="full">
                      <ion-icon [name]="subElement.icon" [color]="subElement.color"></ion-icon>
                      <ion-label> {{ subElement.title }} </ion-label>
                      <mits-badge *ngIf="subElement.badge" [color]="subElement.badge.color"
                        [value]="subElement.badge.text" slot="end">
                      </mits-badge>
                    </ion-item>
                  </ng-container>
                </div>
              </div>
            </div>
          </span>
        </div>
      </ion-content>
      <ion-footer class="ion-text-center">
        <ion-item *ngIf="errorLogHelper.hasAny()" lines="full" [detail]="this.userHelper.getUser()?.is_admin"
          [color]="errorLogHelper.hasErrors() ? 'danger' : errorLogHelper.hasWarnings() ? 'warning' : 'medium'"
          [routerLink]="this.userHelper.getUser()?.is_admin ? 'statusboard' : '.'" class="animatePulseOpacity"
          (click)="errorLogHelper.reloadErrorlogs()">
          <ion-icon
            [name]=" errorLogHelper.hasErrors() || errorLogHelper.hasWarnings() ? 'warning-outline' : 'information-circle-outline'"
            size="large" [ngClass]="{'animatePulseScale': errorLogHelper.hasErrors()}">
          </ion-icon>
          <span class="ion-padding">
            {{ errorLogHelper.hasErrors() || errorLogHelper.hasWarnings() ? 'Einrichtung nicht abgeschlossen!' :
            'Informationen zur weiteren Einrichtung' }}
          </span>
        </ion-item>
        <ion-item class="logged_in" lines="full">
          <ion-icon name="search"></ion-icon>
          <span style="padding: 4px 0px 4px 16px">
            <strong>Status der Suche:</strong>
            <lib-fulltext-counter class="insideMenu"></lib-fulltext-counter>
          </span>
        </ion-item>
        <div class="infoItem" *ngIf="!isOnline">
          <span>Sie sind Offline</span>
          <p>
            Einige Features können ausgeblendet sein, da diese Offline nicht
            verfügbar sind.
          </p>
        </div>
        <ion-item class="logged_in" *ngIf="userHelper.getUser()" lines="full">
          <ion-icon name="person-outline"></ion-icon>
          <span class="ion-padding">Angemeldet als:<br /><strong>{{
              userHelper.getUserString(userHelper.getUser())
              }}</strong></span>
        </ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>