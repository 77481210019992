import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { FunctionSwitchService } from 'src/app/providers/model-services/function-switch.service';
import { UserService } from 'src/app/providers/model-services/user.service';
import { BusinessModel } from '../models/settings/business';
import { DataService } from '../providers/data.service';

@Injectable()
export class BusinessInterceptor implements HttpInterceptor {
  /** All endpoints where no mandator or business should be attached to the url */
  endpointExeptions: string[] = [
    this.inj.get(FunctionSwitchService).endpointWithoutPaginate,
    this.inj.get(UserService).endpointWithUrl + 'current',
  ];

  constructor(private readonly inj: Injector) {
    console.debug(
      '[BusinessInterceptor] Exepted endpoints for businessID param: ',
      this.endpointExeptions
    );
  }

  /**
   * Intercepting the requests heading outwards
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /** Data-Service */
    const dataService = this.inj.get(DataService);

    if (
      this.endpointExeptions.find((exEndpoint) => {
        return exEndpoint == req.url ? exEndpoint : null;
      })
    ) {
      return next.handle(req);
    }

    /** MandatorBusiness-Service */
    const userHelper = this.inj.get(UserHelper);
    /** Current selected business */
    const business: BusinessModel = userHelper.getManBus().business;

    if (business) {
      req = req.clone({
        params: req.params.append('business_id', business.id),
      });
    }

    return next.handle(req);
  }
}
