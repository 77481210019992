import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, Observable, retry } from 'rxjs';
import { ArticleModel } from 'src/app/models/articles/article';
import { ArticleEanCode } from 'src/app/models/articles/article-ean-code';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { UserHelper } from '../helpers/user-helper.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends OfflineDataService<ArticleModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    private userHelper: UserHelper
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Article',
      http,
      errorService,
      events,
      'articles/',
      'article',
      ['created_at', 'updated_at', 'created_by_id', 'unit'],
      ['eans'],
      'ean'
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'pricetag';
  }

  /**
   * loads article with name 'Fahrzone'
   * @returns geet-request to articles/drive_zone
   */
  filterForDriveZone(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'drive_zone')
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Gibt die ersten 25 Artikel zurück die gefunden werden
   * Falls keine gefunden werden, werden nur die ersten 25 Artikel zurückgegeben (ohne Suche)
   * @param searchText
   * @returns
   */
  public async fastLimitedSearch(searchText: string): Promise<ArticleModel[]> {
    if (searchText?.length < 3) {
      const foundArticles = await this.localAllwithPaging(1);
      return foundArticles.data;
    } else {
      return this.fullTextSearch(searchText);
    }
  }

  /**
   * Speichert die Daten in dem Store weg
   * @param {ArticleModel} obj
   * @param {string} updatedAt
   */
  protected async store(obj: ArticleModel, updatedAt: string) {
    const storedObj = this.createStoredData(obj);
    const currentObj = await this.localFind(obj.id);

    if (
      this.userHelper
        .getUser()
        .groups.findIndex(
          (g) => g.group_name === 'Aussendienst' || g.group_name === 'Befüllung'
        ) >= 0
    ) {
      if (currentObj) {
        if (currentObj.content.name !== obj.name) {
          this.fulltextService.add(storedObj, ['name']);
        }

        if (obj.eans) {
          this.addEansToFulltext(
            obj.id,
            obj.eans.filter((ean) => {
              return (
                currentObj.content.eans &&
                !currentObj.content.eans.find((currentEan) => {
                  return currentEan.remote_id === ean.remote_id;
                })
              );
            })
          );

          this.addEansToFulltext(
            obj.id,
            currentObj.content.eans.filter((ean) => {
              return (
                currentObj.content.eans &&
                !obj.eans.find((currentEan) => {
                  return currentEan.remote_id === ean.remote_id;
                })
              );
            }),
            true
          );
        }
      } else {
        this.fulltextService.add(storedObj, ['name']);

        this.fulltextService.insertIntoWorkflowQueue(
          obj.article_number,
          obj.id,
          this.type
        );
        this.addEansToFulltext(obj.id, obj.eans);
      }
    }

    return super.store(obj, updatedAt);
  }

  /**
   * Fügt die EANS an den Index
   * @param {number} objId
   * @param {ArticleEanCode[]} eans
   * @return
   */
  private addEansToFulltext(
    objId: number,
    eans: ArticleEanCode[],
    remove: boolean = false
  ) {
    if (!eans) return;

    for (const ean of eans) {
      this.fulltextService.insertIntoWorkflowQueue(
        ean.remote_id,
        objId * (remove ? -1 : 1),
        this.type
      );
    }
  }

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param {number} id
   * @return {Promise<any>}
   */
  public async localDelete(id: number): Promise<any> {
    const currentObj = await this.localFind(id);
    await super.localDelete(id);
    if (currentObj) {
      await this.fulltextService.add(currentObj, ['name'], true);
      this.addEansToFulltext(id, currentObj.content.eans, true);
    }
  }

  /**
   * Status der Volltextverarbeitung prüfen
   */
  public async fulltextRunning(): Promise<boolean> {
    return this.fulltextService.entriesEnqueued();
  }

  /**
   * Volltext verarbeitung stoppen
   */
  public fulltextStop() {
    this.fulltextService.stopWorker();
  }

  /**
   * Volltext verarbeitung starten
   */
  public fulltextStart() {
    this.fulltextService.startWorker();
  }

  /**
   * loads article with name 'Arbeitseinheit'
   * @returns geet-request to articles/work_articles
   */
  filterForWork(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'work_articles')
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Ruft ein Objekt mit Details ab
   */
  findEAN(ean): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + '?ean_code=' + ean)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
